import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import './Home.css';
import Cookies from 'js-cookie';
import { IoHeart } from "react-icons/io5";
import { IoHeartDislike } from "react-icons/io5";

const Home = () => {
  const [artists, setArtists] = useState([]);
  const [perSongs, setPerSongs] = useState([]);
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSong, setSelectedSong] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [perModalVisible, setPerModalVisible] = useState(false);
  const [recommendationModalVisible, setRecommendationModalVisible] = useState(false);
  const [recommendedSongs, setRecommendedSongs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSongs();
  }, []);

  const fetchSongs = async () => {
    try {
      const token = Cookies.get('Authorization');
      const response = await axios.get('/auth/getsongs', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = response.data;
      if (data !== null) {
        const shuffledSongs = data.sort(() => 0.5 - Math.random());
        const selectedSongs = shuffledSongs.slice(0, 3);
        setPerSongs(selectedSongs);
      }
    } catch (error) {
      console.error('Error fetching songs:', error);
    }
  };

  const removeSong = async () => {
    const token = Cookies.get('Authorization');
    try {
      await axios.post(
        '/auth/removesong',
        { songUri: selectedSong.uri },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Song Removed!');
      fetchSongs();
      closeModal();
    } catch (error) {
      console.error('Error removing song:', error);
      alert('Error removing song');
    }
  };

  const closeRecommendationModal = () => {
    setRecommendationModalVisible(false);
    setRecommendedSongs([]);
  };

  const recommendSongs = async () => {
    try {
      const response = await axios.get('/song/recommendsongs', {
        params: { trackUri: selectedSong.uri },
      });
      setRecommendedSongs(response.data);
      setModalVisible(false);
      setRecommendationModalVisible(true);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      alert('Error fetching recommendations');
    }
  };

  useEffect(() => {
    const fetchTopArtists = async () => {
      try {
        const response = await axios.get('/song/topartists');
        const artists = response.data.slice(0, 10).map(artist => ({
          name: artist.name,
          images: artist.images,
          popularity: artist.popularity,
        }));
        artists.sort((a, b) => b.popularity - a.popularity);
        setArtists(artists.slice(0, 10));
      } catch (error) {
        console.error("Error fetching top artists:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTopArtists();
  }, []);

  useEffect(() => {
    const fetchTopSongs = async () => {
      try {
        const res = await axios.get('/song/topsongs');
        const songs = res.data.slice(0, 10).map(song => ({
          name: song.name,
          artist: song.artist,
          album: song.album,
          uri: song.uri,
          images: song.images,
        }));
        setSongs(songs);
      } catch (error) {
        console.error("Error fetching top songs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTopSongs();
  }, []);

  const addSong = async (song) => {
    const token = Cookies.get('Authorization');
    try {
      await axios.post(
        '/auth/addsong',
        { song: song },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Song Added!');
      closeModal();
    } catch (error) {
      console.error('Error adding song:', error);
      alert('Error adding song');
    }
  };

  const dislikeSong = () => {
    console.log("hi")
  }

  const likeSong = () => {
    console.log("hi")
  }

  const handleSongClick = (song) => {
    setSelectedSong(song);
    setModalVisible(true);
  };

  const handlePerSongClick = (song) => {
    setSelectedSong(song);
    setPerModalVisible(true);
  };

  const closePerModal = () => {
    setPerModalVisible(false);
    setSelectedSong(null);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedSong(null);
  };

  const handleArtistClick = (artist) => {
    navigate(`/song?artist=${artist.name}`);
  };

  const truncateTitle = (title, maxLength) => {
    return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
  };

  return (
    <>
      <Header />
      <div className='Home'>

        <div className='Top-artists'>
          <h2 className='home-header'>TOP ARTISTS</h2>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ul>
              {artists.map((artist, index) => (
                <li key={index}>
                  <h3 className='home-hd'>{truncateTitle(artist.name, 15)}</h3>
                  {artist.images.length > 0 && (
                    <div className='img-container' onClick={() => handleArtistClick(artist)}>
                      <img src={artist.images[0].url} alt={`${artist.name}`} />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className='Top-songs'>
          <h2 className='home-header'>TOP SONGS</h2>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ul>
              {songs.map((song, index) => (
                <li key={index}>
                  <h3 className='home-hd'>{truncateTitle(song.name, 19)}</h3>
                  {song.images.length > 0 && (
                      <div className="img-container" onClick={() => handleSongClick(song)}>
                        <img src={song.images[0].url} alt={song.name} />
                      </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className='Random-songs'>
          <h2 className='home-header'>Your Songs</h2>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="img-container">
            {perSongs.length > 0 && (
              <ul>
                {perSongs.map((result, index) => (
                  <li key={index}>
                <h3 className='home-hd'>{truncateTitle(result.name, 30)}</h3> 
                    {result.images.length > 0 && (
                      <div className="img-container" onClick={() => handlePerSongClick(result)}>
                        <img src={result.images[0].url} alt={result.name} />
                      </div>
                    )}
                    <p className='home-para'>{truncateTitle(result.artist, 30)}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
          )}
        </div>

      </div>

      {modalVisible && selectedSong && (
        <div className="modal">
          <div className="modal-content">
            <iframe
              src={`https://open.spotify.com/embed/track/${selectedSong.uri.split(':')[2]}`}
              width="300"
              height="400"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
              title="Spotify Player"
            ></iframe>
            <div className="button-group">
              <button className='add-btn' onClick={() => addSong(selectedSong)}>Add</button>
              <button className='close-btn' onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>
      )}

      {perModalVisible && selectedSong && (
        <div className="modal">
          <div className="modal-content">
            <iframe
              src={`https://open.spotify.com/embed/track/${selectedSong.uri.split(':')[2]}`}
              width="300"
              height="400"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
              title="Spotify Player"
            ></iframe>
            <div className="button-group">
              <button className='recom-btn' onClick={recommendSongs}>Recommended</button>
              <button className='remove-btn' onClick={removeSong}>Remove</button>
              <button className='close-btn' onClick={closePerModal}>Close</button>
            </div>
          </div>
        </div>
      )}

      {recommendationModalVisible && (
        <div className="modal">
          <div className="rec-modal-content">
            <h2 className='rec-title'>Recommended Songs</h2>
            <ul className="recommended-songs-list">
              {recommendedSongs.map((song, index) => (
                <li key={index} className="recommended-song-item">
                  <iframe
                    src={`https://open.spotify.com/embed/track/${song.uri.split(':')[2]}`}
                    width="300"
                    height="175"
                    frameBorder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                    title={`Spotify Player ${index}`}
                  ></iframe>
                  <div className="button-group">
                    <button className='recom-btn' onClick={() => addSong(song)}>Add</button>
                    <button className='like-btn' onClick={likeSong}><IoHeart /></button>
                    <button className='dislike-btn' onClick={dislikeSong}><IoHeartDislike /></button>
                  </div>
                  <div className="recommended-song-info">
                  </div>
                </li>
              ))}
            </ul>
            <div className="button-group">
              <button className='close-btn' onClick={closeRecommendationModal}>Close</button>
            </div>
          </div>
        </div>
      )}

                    
    </>
  );
};

export default Home;
