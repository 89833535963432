import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './component/Home';
import Login from './component/Login';
import Register from './component/Register';
import Landing from './component/Landing';
import Profile from './component/Profile';
import Artist from './component/Artist';
import Song from './component/Song';
import Genre from './component/Genre';
import Saved from './component/Saved';
import PrivateRoute from './component/PrivateRoute';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={ <PrivateRoute><Home /></PrivateRoute> } />
          <Route path="/profile" element={ <PrivateRoute><Profile /></PrivateRoute> } />
          <Route path="/artist" element={ <PrivateRoute><Artist /></PrivateRoute> } />
          <Route path="/song" element={ <PrivateRoute><Song /></PrivateRoute> } />
          <Route path="/genre" element={ <PrivateRoute><Genre /></PrivateRoute> } />
          <Route path="/saved" element={ <PrivateRoute><Saved /></PrivateRoute> } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
