import React, { useState, useEffect } from 'react';
import Header from './Header';
import axios from 'axios';
import Cookies from 'js-cookie';
import './Saved.css';
import { IoHeart } from "react-icons/io5";
import { IoHeartDislike } from "react-icons/io5";

function Saved() {
  const [songs, setSongs] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [recommendationModalVisible, setRecommendationModalVisible] = useState(false);
  const [recommendedSongs, setRecommendedSongs] = useState([]);
  const [lookUp, setLookUP] = useState("");
  const [songResults, setSongResults] = useState([]);

  useEffect(() => {
    fetchSongs();
  }, []);

  const fetchSongs = async () => {
    try {
      const token = Cookies.get('Authorization');
      const response = await axios.get('/auth/getsongs', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setSongs(response.data);
      console.log(songResults)
      filterSongs(response.data)
    } catch (error) {
      console.error('Error fetching songs:', error);
    }
  };

  const removeSong = async () => {
    const token = Cookies.get('Authorization');
    try {
      await axios.post(
        '/auth/removesong',
        { songUri: selectedSong.uri },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Song Removed!');
      fetchSongs();
      closeModal();
    } catch (error) {
      console.error('Error removing song:', error);
      alert('Error removing song');
    }
  };

  const addSong = async (song) => {
    const token = Cookies.get('Authorization');
    try {
      await axios.post(
        '/auth/addsong',
        { song: song },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Song Added!');
      await fetchSongs();
      filterSongs(null);
      closeModal();
    } catch (error) {
      console.error('Error adding song:', error);
      alert('Error adding song');
    }
  };
  

  const dislikeSong = () => {
    console.log("hi")
  }

  const likeSong = () => {
    console.log("hi")
  }

  const handleSongClick = (song) => {
    setSelectedSong(song);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedSong(null);
  };

  const closeRecommendationModal = () => {
    setRecommendationModalVisible(false);
    setRecommendedSongs([]);
  };

  const truncateTitle = (title, maxLength) => {
    return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
  };

  const recommendSongs = async () => {
    try {
      const response = await axios.get('/song/recommendsongs', {
        params: { trackUri: selectedSong.uri },
      });
      setRecommendedSongs(response.data);
      setModalVisible(false);
      setRecommendationModalVisible(true);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      alert('Error fetching recommendations');
    }
  };

  const handleSearchEvent = (event) => {
    setLookUP(event.target.value);
  };

  const filterSongs = (data) => {
    if (data === null) {
      const filteredSongs = songs.filter(song =>
        song.name.toLowerCase().includes(lookUp.toLowerCase())
      );
      setSongResults(filteredSongs);
    } else {
      const filteredSongs = data.filter(song =>
        song.name.toLowerCase().includes(lookUp.toLowerCase())
      );
      setSongResults(filteredSongs);
    }
  };
  
  const handleSearch = (event) => {
    event.preventDefault();
    filterSongs(null);
  };
  

  return (
    <>
      <Header />
      <form onSubmit={handleSearch} className="search-form">
        <input
          type="text"
          value={lookUp}
          onChange={handleSearchEvent}
          placeholder="Search for songs by title..."
          className="search-bar"
        />
        <button type="submit" className="search-button">Search</button>
      </form>
      <div className="search-container">
        <div className="saved-results">
          {songResults.length > 0 && (
            <ul>
              {songResults.map((result, index) => (
                <li key={index} className="saved-result-item">
                <h3>{truncateTitle(result.name, 30)}</h3> 
                  {result.images.length > 0 && (
                    <div className="img-container" onClick={() => handleSongClick(result)}>
                      <img src={result.images[0].url} alt={result.name} />
                    </div>
                  )}
                  <p className="artist-name">{truncateTitle(result.artist, 30)}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {modalVisible && selectedSong && (
        <div className="modal">
          <div className="modal-content">
            <iframe
              src={`https://open.spotify.com/embed/track/${selectedSong.uri.split(':')[2]}`}
              width="300"
              height="400"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
              title="Spotify Player"
            ></iframe>
            <div className="button-group">
            <button className='recom-btn' onClick={recommendSongs}>Recommended</button>
              <button className='remove-btn' onClick={removeSong}>Remove</button>
              <button className='close-btn' onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>
      )}

      {recommendationModalVisible && (
        <div className="modal">
          <div className="rec-modal-content">
            <h2 className='rec-title'>Recommended Songs</h2>
            <ul className="recommended-songs-list">
              {recommendedSongs.map((song, index) => (
                <li key={index} className="recommended-song-item">
                  <iframe
                    className='spotify-iframe'
                    src={`https://open.spotify.com/embed/track/${song.uri.split(':')[2]}`}
                    width="300"
                    height="175"
                    frameBorder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                    title={`Spotify Player ${index}`}
                  ></iframe>
                  <div className="button-group">
                    <button className='recom-btn' onClick={() => addSong(song)}>Add</button>
                    <button className='like-btn' onClick={likeSong}><IoHeart /></button>
                    <button className='dislike-btn' onClick={dislikeSong}><IoHeartDislike /></button>
                  </div>
                  <div className="recommended-song-info">
                  </div>
                </li>
              ))}
            </ul>
            <div className="button-group">
              <button className='close-btn' onClick={closeRecommendationModal}>Close</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Saved;
