import React, { useState } from 'react';
import Header from './Header';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Artist.css';
import Cookies from 'js-cookie';

const Genre = () => {
  const [lookUp, setLookUP] = useState("");
  const [artistResults, setArtistResults] = useState([]);
  const [songResults, setSongResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSong, setSelectedSong] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleSearchEvent = (event) => {
    setLookUP(event.target.value);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const res = await axios.get(`/song/search?query=${lookUp}&type=genre`);
      setArtistResults(res.data[0] || []);
      setSongResults(res.data[1] || []);
      setError(null);
    } catch (err) {
      console.error("Search error", err);
      setError("An error occurred while searching.");
    } finally {
      setLoading(false);
    }
  };

  const addSong = async () => {
    const token = Cookies.get('Authorization');
    try {
      await axios.post(
        '/auth/addsong',
        { song: selectedSong },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Song Added!');
      closeModal();
    } catch (error) {
      console.error('Error adding song:', error);
      alert('Error adding song');
    }
  };

  const handleArtistClick = (artist) => {
    navigate(`/song?artist=${artist.name}`);
  };

  const handleSongClick = (song) => {
    setSelectedSong(song);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedSong(null);
  };

  return (
    <>
      <Header />
      <form onSubmit={handleSearch} className="search-form">
        <input
          type="text"
          value={lookUp}
          onChange={handleSearchEvent}
          placeholder="Search for artists or songs..."
          className="search-bar"
        />
        <button type="submit" className="search-button">Search</button>
      </form>
      <div className="button-container">
        <Link to="/artist" className="nav-button">Artists</Link>
        <Link to="/song" className="nav-button">Songs</Link>
        <Link to="/genre" className="navi-button">Genres</Link>
      </div>
      <div className="search-container">
        <div className="search-results">
          {artistResults.length > 0 && <h1>Artists</h1>}
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              {artistResults.length > 0 && (
                <ul>
                  {artistResults.map((result, index) => (
                    <li key={index} className="search-result-item">
                      <h3>{result.name}</h3>
                      {result.images.length > 0 && (
                        <div className='image-container' onClick={() => handleArtistClick(result)}>
                          <img src={result.images[0].url} alt={result.name} />
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>

        <div className="search-results">
          {songResults.length > 0 && <h1>Songs</h1>}
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              {songResults.length > 0 && (
                <ul>
                  {songResults.map((result, index) => (
                    <li key={index} className="search-result-item">
                      <h3>{result.name}</h3>
                      {result.images.length > 0 && (
                        <div className="image-container" onClick={() => handleSongClick(result)}>
                          <img src={result.images[0].url} alt={result.name} />
                        </div>
                      )}
                      <p>{result.artist}</p>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      </div>


      {modalVisible && selectedSong && (
        <div className="modal">
          <div className="modal-content">
            <iframe
              src={`https://open.spotify.com/embed/track/${selectedSong.uri.split(':')[2]}`}
              width="300"
              height="400"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
              title="Spotify Player"
            ></iframe>
            <div className="button-group">
              <button className='add-btn' onClick={addSong}>Add</button>
              <button className='close-btn' onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>
      )}



    </>
  );
};

export default Genre;