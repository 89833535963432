import { React, useState, useEffect } from 'react';
import Header from './Header';
import './Profile.css';
import def from '../pic/default_pfp.png';
import axios from 'axios';
import Cookies from 'js-cookie';

const Profile = () => {
  const [currUser, setCurrUser] = useState("");
  const [currEmail, setCurrEmail] = useState("");
  const [currPfp, setCurrPfp] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = Cookies.get('Authorization');
        const response = await axios.get(`/auth/getprofile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        const data = response.data;
        setCurrUser(data.profile.name);
        setCurrEmail(data.profile.email);
        if (data.profile.profile_picture) {
          setCurrPfp(`data:image/jpeg;base64,${data.profile.profile_picture}`);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = Cookies.get('Authorization');
    const form = new FormData();
    if (formData.username !== '') { form.append('username', formData.username); }
    form.append('password', formData.password);
    if (selectedFile) {
      form.append('profile_picture', selectedFile);
    }

    try {
      await axios.put('/auth/updateprofile', form, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  return (
    <div>
      <Header />
      <div className='Profile'>
        <div className="upload-pic">
          <img src={currPfp || def} alt="Profile" />
        </div>
        <form className='prof-box' onSubmit={handleSubmit}>
          <div className="input-box">
            <input
              type="text"
              placeholder={currUser}
              id="username"
              value={formData.username}
              onChange={handleChange}
            />
          </div>
          <div className="input-box">
            <input
              type="text"
              value={currEmail}
              id="email"
              readOnly
            />
          </div>
          <div className="input-box">
            <input
              type="password"
              placeholder="Password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-box">
            <input
              type="file"
              id="profile_picture"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <button className='profile-button' type="button" onClick={() => document.getElementById('profile_picture').click()}>Upload Profile Picture</button>
          </div>
          <button className='profile-button' type="submit">Save Change</button>
        </form>
      </div>
    </div>
  );
}

export default Profile;
