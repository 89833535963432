import React from 'react';
import { Link } from 'react-router-dom';
import './Landing.css'; // Import your CSS file

const Landing = () => {

  return (
    <div className='landing'>
      <header className='landing-header'>
        <h1>Motif</h1>
        <p>Your personal music recommendation service</p>
      </header>
      <div className='landing-content'>
        <div className='intro'>
          <h2>Discover Your Next Favorite Song</h2>
          <p>Motif curates personalized song recommendations just for you. Explore new music based on your tastes and preferences.</p>
        </div>
        <div className='cta-buttons'>
          <Link to="/login"><button className='auth-button'>Login</button></Link>
          <Link to="/register"><button className='auth-button'>Register</button></Link>
        </div>
      </div>
      <footer className='landing-footer'>
        <p>© 2024 Motif. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Landing;
