import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import './Artist.css';

const Artist = () => {
  const [lookUp, setLookUP] = useState("");
  const [artistResults, setArtistResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSearchEvent = (event) => {
    setLookUP(event.target.value);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const res = await axios.get(`/song/search?query=${lookUp}&type=artist`);
      setArtistResults(res.data);
      setError(null);
    } catch (err) {
      console.error("Search error", err);
      setError("An error occurred while searching.");
    } finally {
      setLoading(false);
    }
  };

  const handleArtistClick = (artist) => {
    navigate(`/song?artist=${artist.name}`);
  };

  return (
    <>
      <Header />
      <form onSubmit={handleSearch} className="search-form">
        <input
          type="text"
          value={lookUp}
          onChange={handleSearchEvent}
          placeholder="Search for artists or songs..."
          className="search-bar"
        />
        <button type="submit" className="search-button">Search</button>
      </form>
      <div className="button-container">
        <Link to="/artist" className="navi-button">Artists</Link>
        <Link to="/song" className="nav-button">Songs</Link>
        <Link to="/genre" className="nav-button">Genres</Link>
      </div>
      <div className="search-container">
        <div className="search-results">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              {artistResults.length > 0 && (
                <ul>
                  {artistResults.map((result, index) => (
                    <li key={index} className="search-result-item">
                      <h3>{result.name}</h3>
                      {result.images.length > 0 && (
                        <div className="image-container" onClick={() => handleArtistClick(result)}>
                          <img src={result.images[0].url} alt={result.name} />
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Artist;
