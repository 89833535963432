import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../pic/motif.jpeg';
import def from '../pic/default_pfp.png';
import spotify from '../pic/spotify.png';
import Cookies from 'js-cookie';
import axios from 'axios';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currUser, setCurrUser] = useState("");
  const [currPfp, setCurrPfp] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = Cookies.get('Authorization');
        const response = await axios.get(`/auth/getprofile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        const data = response.data;
        let name = '';
        if (data.profile.name.length > 14) {
          name = data.profile.name.substring(0, 11) + '...';
        } else {name = data.profile.name}
        console.log(name)
        setCurrUser(name);
        if (data.profile.profile_picture !== null) {
          setCurrPfp(`data:image/jpeg;base64,${data.profile.profile_picture}`);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  const handleLogout = () => {
    Cookies.remove('Authorization');
    navigate('/');
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/home"><img src={logo} alt="Logo" /></Link>
        <Link to="https://open.spotify.com/">
          <div className="tooltip">
            <img className='spotify' src={spotify} alt="Spotify" />
            <span className="tooltiptext">All data used is from the Spotify API</span>
          </div>
        </Link>
      </div>
      <div className={`nav-container ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
        <div className="hamburger" onClick={toggleMobileMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <nav className="nav">
          <Link to="/home" className="navig-link">Spotlight</Link>
          <Link to="/artist" className="nav-link">Search</Link>
          <Link to="/saved" className="nav-link">Saved</Link>
          <Link to="/profile" className="hidden-nav-link">Profile</Link>
        </nav>
      </div>
      <div className='profile'>
        <div className="prof-pic">
          <Link to="/profile"><img src={ currPfp || def} alt="Profile" /></Link>
        </div>
        <div className='user-txt'>
          <p>{currUser || "defaultUser"}</p>
          <p onClick={handleLogout} style={{ cursor: 'pointer' }}>Log Out</p>
        </div>
      </div>
      
    </header>
  );
};

export default Header;
