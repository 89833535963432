import React, { useState } from 'react';
import './Register.css';
import logo from '../pic/motif.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setError(""); // Clear error when user starts typing
  };

  const handleSubmit = () => {
    if (!formData.username || !formData.email || !formData.password) {
      setError("All fields are required.");
      return;
    }

    axios.post('/auth/register', formData)
      .then((response) => {
        const token = response.data.token;
        console.log(token);
        Cookies.set('Authorization', token, { expires: 7 });
        navigate('/home');
      })
      .catch((error) => {
        console.error('Error:', error);
        setError("Registration failed. Please try again.");
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className='Register'>
      <div className="mot">
        <Link to="/"><img src={logo} alt="Logo" /></Link>
      </div>
      <h1>Register</h1>
      <div className='reg-box'>
        {error && <p className="error-message">{error}</p>}
        <div className="input-box">
          <input
            type="text"
            placeholder="Username"
            id="username"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>
        <div className="input-box">
          <input
            type="text"
            placeholder="Email"
            id="email"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>
        <div className="input-box">
          <input
            type="password"
            placeholder="Password"
            id="password"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>
        <button className='register-button' onClick={handleSubmit}>Register</button>
      </div>
    </div>
  );
};

export default Register;
